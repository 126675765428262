import { createRouter, createWebHistory } from 'vue-router';

// import IndexPage from '@/pages/Index.vue';
import AuctionList from '@/pages/AuctionList.vue';
import AuctionIndex from '@/pages/AuctionIndex.vue';
import AuctionDetail from '@/pages/AuctionDetail.vue';
import AccountPage from '@/pages/AccountPage.vue';

const routes = [
//   {
//     path: '/',
//     component: IndexPage,
// 	name: 'home',
//     meta: {
//       title: 'Featured Stuff',
//     },
//   },
  {
		path: '/',
		component: AuctionList,
		name: 'auction-index',
		meta: {
			title: 'Active Auctions',
		}
	},
	{
		path: '/:group',
		component: AuctionIndex,
		name: 'auction-listings',
		meta: {
			title: 'Active Auctions',
		}
	},
	{
		path: '/:group/:auction(\\d+)',
		component: AuctionDetail,
		name: 'auction',
	},
	{
		path: '/account',
		component: AccountPage,
		name: 'account',
	},
	{
		path: '/account/:subpage(\\w+)',
		component: AccountPage,
	}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { el: to.hash }
		}

		return { top: 0 }
	}
})

export default router
