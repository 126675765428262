import { Auction } from '@/types/Auction'
import { DateTime } from 'ts-luxon'
import { ref, computed } from 'vue'
import { isAfter, intervalToDuration, Duration } from 'date-fns'

const Target = {
	CountToStart: Symbol('start'),
	CountToExtended: Symbol('extend'),
	CountToEnd: Symbol('end'),
}

export function countdown(auction: Auction) {
	let duration = ref(null)
	let finished = ref(null)
	let interval = null;

	const countState = computed(() => {
		const now = DateTime.now()

		if (now < auction.auction_group.start_at) {
			return Target.CountToStart
		}

		if (now < auction.auction_group.end_at) {
			return Target.CountToExtended
		}

		return Target.CountToEnd
	})

	const timestamp = computed(() => {
		switch (countState.value) {
			case Target.CountToStart:
				return auction.auction_group.start_at

			case Target.CountToExtended:
				return auction.auction_group.end_at

			default:
				if (auction.last_bid_at < auction.auction_group.end_at) {
					return auction.auction_group.end_at.plus( auction.extended_bidding_seconds )
				}

				return auction.last_bid_at.plus( auction.extended_bidding_seconds )
		}
	})

	function updateCountdown() {
		const now = DateTime.now()
		finished = isAfter(
			now.toJSDate(),
			timestamp.value.toJSDate()
		)

		if (finished.value) {
			_clearInterval()
			return;
		}

		duration = intervalToDuration({
			start: now.toJSDate(),
			end: timestamp.value.toJSDate(),
		})
	}

	function _clearInterval() {
		window.clearInterval(interval)
	}

	function destruct() {
		_clearInterval()
	}

	interval = setInterval(updateCountdown, 450)
	updateCountdown()

	return {
		countState,
		timestamp,
		finished,
		duration,
		Target,
		destruct,
	}
}
