<template>
	<div class="border-2 border-slate-200 rounded-md overflow-hidden">
		<table class="w-full table-auto">
			<thead>
				<tr class="text-center bg-slate-200">
					<th class="text-left px-4 py-2">Bidder</th>
					<th class="text-left py-2">Amount</th>
					<th class="">&nbsp;</th>
				</tr>
			</thead>
			<tbody v-if="bids.length" class="text-sm text-slate-500">
				<tr v-for="bid in bids" :key="`bid-${bid.bid_at}-${bid.bidder}`">
					<th class="py-2 px-4 w-[40%] text-left font-normal" scope="row">
						<span :title="bid.bidder">
							{{ `${bid.bidder.substring(0, 6)}...${bid.bidder.substring(bid.bidder.length - 6)}` }}
						</span>
					</th>
					<td class="text-left py-2">{{ $format((new Currency(bid.bid)).toDecimal()) }} {{ appStore.tokenSymbol }}</td>
					<td class="py-2 px-4 w-[40%] text-right">
						<a :href="`http://etherscan.io/tx/${bid.tx_hash}`" title="View on etherscan"
							class="text-slate-600 underline">
							{{ bid.bid_at.toFormat('f ZZZZ') }}
						</a>
					</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td colspan="3">
						<p class="py-4 text-center text-slate-400">No bids yet</p>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<td colspan="3" class="bg-gray-200 py-1"></td>
				</tr>
			</tfoot>
		</table>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, watch, ref } from "vue";
import { useAppStore } from "../stores/AppStore";
import Currency from "../types/Currency";

export default defineComponent({
	props: {
		bids: {
			required: true,
		}
	},
	setup(props) {
		const appStore = useAppStore();
		const bids = ref(sortBids())

		watch(() => props.bids, () => {
			// console.log('resorting bids')
			bids.value = sortBids()
		});

		function sortBids() {
			return props.bids.sort((left, right) => left - right)
		}

		return {
			props,
			appStore,
			Currency,
			bids
		}
	}
})
</script>
