import axios from 'axios'
import Errors from '@/util/Errors'
import { Auction, AuctionImage, Bid, AuctionGroup } from '@/types/Auction'
import { Taxonomy, Tag } from '@/types/Taxonomy'
import { DateTime, Duration } from 'ts-luxon'

const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

function parseAuctionApiObject(auction: any) : Auction {
	let a: Auction = {
		id: auction.id,
		blockchain_auction_id: auction.blockchain_auction_id,
		seller_token_id: auction.seller_token_id,
		title: auction.title,
		description: auction.description,
		md_description: auction.md_description,
		starting_bid: auction.starting_bid,
		buyer_premium_bps: auction.buyer_premium_bps,
		bid_increment: auction.bid_increment,
		// start_at: DateTime.fromISO(auction.start_at),
		// end_at: DateTime.fromISO(auction.end_at),
		auction_group: {
			id: auction.auction_group.id,
			name: auction.auction_group.name,
			slug: auction.auction_group.slug,
			image: auction.auction_group.image,
			start_at: DateTime.fromISO(auction.auction_group.start_at),
			end_at: DateTime.fromISO(auction.auction_group.end_at),
		},
		extended_bidding_seconds: Duration.fromObject({ seconds: auction.extended_bidding_seconds }),
		bid_count: auction.bid_count,
		high_bid: auction.high_bid,
		high_bidder: auction.high_bidder,
		last_bid_at: DateTime.fromISO(auction.last_bid_at),
		images: [] as AuctionImage[],
		bids: [] as Bid[],
		tags: [] as Tag[],
	}

	auction.image.map((img: AuctionImage) => {
		a.images.push({
			uri: img.uri,
			display_order: img.display_order,
		});
	});

	if (auction.bid) {
		auction.bid.map((bid: Bid) => {
			a.bids.push(parseBidApiObject(bid))
		});
	}

	if (auction.tags) {
		auction.tags.map((tag: Tag) => {
			a.tags.push(parseTagApiObject(tag));
		});
	}

	return a;
}

function parseBidApiObject(bid: Bid) {
	const b = {
		auction_id: bid.auction_id,
		blockchain_auction_id: bid.blockchain_auction_id,
		bid: bid.bid,
		bidder: bid.bidder,
		tx_hash: bid.tx_hash,
		bid_at: DateTime.fromISO(bid.bid_at),
	}

	return b;
}

function parseTagApiObject(tag: Tag) {
	return {
		id: tag.id,
		name: tag.name,
		slug: tag.slug,
	} as Tag;
}

function parseTaxonomy(data: any) : Taxonomy {
	// console.log(data)
	let t : Taxonomy = {
		id: data.id,
		name: data.name,
		slug: data.slug,
		tags: [] as Tag[],
	}

	data.tags.map((tag: any) => {
		t.tags.push(parseTagApiObject(tag));
	})

	return t
}

function apiUri(path: string) {
	return `${API_ENDPOINT}${path}`;
}

export default {
	async fetchAuctions(
		page: number | null,
		sort: string | null,
		limit: number | null,
	) : Promise<Auction[]> {
		const response = await axios.get(apiUri(`/api/auctions`), {
			page,
			sort,
			limit
		});

		if (response.status !== 200) {
			throw Errors.HttpError("Failed to fetch auction index", response)
		}

		let auctions: Auction[] = response.data.data.map((auction: any) => {
			return parseAuctionApiObject(auction)
		});

		return auctions;
	},

	async fetchAuction(id: number) : Promise<Auction> {
		const response = await axios.get(apiUri(`/api/auction/${id}`))

		if (response.status !== 200) {
			throw Errors.HttpError("Failed to fetch auction", response)
		}

		return parseAuctionApiObject(response.data.data)
	},

	async fetchTaxonomy() : Promise<Taxonomy[]> {
		const response = await axios.get(apiUri(`/api/taxonomy`))

		if (response.status != 200) {
			throw Errors.HttpError("Failed to fetch taxonomy", response)
		}

		return response.data.data.map((data: any) : Taxonomy => {
			return parseTaxonomy(data)
		}) as Taxonomy[]
	},

	async notifyBid(txHash: string) : Promise {
		return await axios.post(apiUri(`/api/auction/bid`), {
			txHash: txHash,
		})
	},

	async loadProfile(token: AuthToken) : Promise {
		const response = await axios.get(apiUri(`/api/account`), {
			headers: {
				'Authorization': `Bearer ${token.base64_encode()}`,
			}
		});

		if (response.status !== 200) {
			return null;
		}

		return {
			profile: {},
			watched_auctions: response.data.watched_auctions.map(parseAuctionApiObject),
			bid_auctions: response.data.bid_auctions.map(parseAuctionApiObject),
			won_auctions: response.data.won_auctions.map(parseAuctionApiObject),
			owned_auctions: response.data.owned_auctions.map(parseAuctionApiObject),
			bids: response.data.bids.map(parseBidApiObject),
		}
	},

	async watchAuction(token: AuthToken, auctionId: number, watch: boolean) : Promise<Auction[]> {
		const method = watch? 'post' : 'delete'
		const response = await axios({
			url: apiUri(`/api/account/auctions/watch`),
			method,
			data: {
				id: auctionId
			},
			headers: {
				'Authorization': `Bearer ${token.base64_encode()}`
			}
		});

		if (response.status !== 200) {
			return [] as Auction[];
		}

		return response.data
	}
}
