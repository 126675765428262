<template>
	<div>
		<auction-breadcrumbs class="hidden md:block px-4"
			:group="auctionStore.auction? auctionStore.auction.auction_group : null"
			:auction="auctionStore.auction"
		/>

	<auction-loading v-if="! state.loaded" />
	<div v-else>

		<div class="md:grid md:grid-cols-[2fr,3fr]">
			<div class="overflow-hidden p-6 bg-white md:bg-transparent" id="auction-image">
				<auction-image-carousel
					:images="auctionStore.auction? auctionStore.auction.images : []"
				/>
			</div>
			<div>
				<auction-breadcrumbs class="md:hidden"
					:group="auctionStore.auction.auction_group"
					:auction="auctionStore.auction"
				/>

				<sticky-auction-banner
					v-if="state.showStickyBanner"
					:auction="auctionStore.auction"
					@bid="state.showBidModal = true"
				/>

				<h2 class="font-bold text-xl mt-3 mx-4"
					ref="auctionTitle">
					{{ auctionStore.auction.title }}
				</h2>
				<div class="leading-none text-xs mx-4 mt-1">
					<div v-if="auctionStore.auction.high_bidder?.toLowerCase() == walletStore.wallet?.toLowerCase()"
						class="inline-block rounded bg-green-600 text-green-100 text-xs font-medium px-4 py-1 leading-none">
						High Bidder
					</div>
					<div v-else-if="auctionStore.auction?.bids.filter(b => b.bidder.toLowerCase() == walletStore.wallet?.toLowerCase()).length"
						class="inline-block rounded bg-red-500 text-gray-50 text-xs font-medium px-4 py-1 leading-none">
						Outbid
					</div>
				</div>
				<!-- <auction-countdown class="mx-4"
					:auction="auctionStore.auction"
				/> -->
				<div class="mt-2 rounded">
					<div  class="bg-white mx-4 rounded-xl  px-4 py-4 drop-shadow">
						<p class="">
							<span v-if="auctionStore.auction.bid_count > 0">
								Current Bid
							</span>
							<span v-else>
								Starting Bid
							</span>
						</p>
						<p class="font-semibold text-2xl">
							{{ $format(currentBid.toDecimal()) }} {{ appStore.tokenSymbol }}
						</p>
						<p class="text-xs text-gray-500">
							w/Buyer's Premium:
							{{ $format(currentBid.addPercentage(auctionStore.auction.buyer_premium_bps).toDecimal()) }} {{ appStore.tokenSymbol }}
						</p>

						<div class="text-sm text-slate-500 mt-4">
							<p class="font-medium">
								Minimum bid:
								{{ $format(minimumBid.toDecimal()) }} {{ appStore.tokenSymbol }}
							</p>
							<p class="text-xs mt-1">
								w/Buyer's Premium:
								{{ $format(minimumBid.addPercentage(auctionStore.auction.buyer_premium_bps).toDecimal()) }}
								{{ appStore.tokenSymbol }}
							</p>
						</div>
					</div>
				</div>

				<div class="mx-4 mt-6" ref="customBid">
					<div class="flex items-center space-x-2">
						<div class="flex-shrink flex-grow flex items-center bg-white drop-shadow rounded-xl overflow-hidden space-x-2">
							<input
								v-model="state.bid"
								type="number"
								max="5000000000"
								@focus.stop="$event.target.select() || false"
								@keyup="keyupValidator"
								@change="keyupValidator"
								class="flex-auto text-xl p-2 outline-none text-right w-full">
							<p class="text-gray-400 pr-4">{{  appStore.tokenSymbol }}</p>
						</div>

						<!-- <div class="flex-auto">
							<input type="text"
								class="text-xl p-2 outline-none text-right block w-full py-3 px-3 border border-slate-900 text-slate-700 rounded-md leading-none"
								:placeholder="`Minimum Bid ${currentBid.add(auctionStore.auction.bid_increment).toDecimal()} ${appStore.tokenSymbol}`">
						</div> -->
						<div>
							<button
								class="block w-full btn-primary-lg whitespace-nowrap"
								@click.stop="handleBidClicked">
								Bid Now
							</button>
						</div>
					</div>

					<p v-if="state.error" class="mt-1 text-xs text-red-500">{{ state.error }}</p>
					<p class="mt-1 text-center text-xs text-slate-500">
						Note: All bids placed are spot bids.
					</p>
				</div>

				<auction-countdown class="mx-4 py-4"
					:auction="auctionStore.auction"
				/>

				<div class="my-6 mx-4">
					<h2 class="text-lg font-bold mb-2 border-b-2 border-slate-200">Description</h2>
					<div class="markdown space-y-2" v-html="auctionStore.auction.md_description">
					</div>
				</div>

				<div class="space-x-2 mx-2 my-4">
					<span class="text-xs font-semibold text-slate-600">Categories</span>
					<span v-for="tag in auctionStore.auction.tags"
						class="text-xs inline-block px-2 py-.5 bg-slate-200 text-slate-500 rounded-lg">
						{{ tag.name }}
					</span>
				</div>

				<div class="my-8 mx-2">
					<h2 id="bid-history" class="text-lg font-bold mb-2 border-b-2 border-slate-200">Bid History</h2>
					<auction-bid-history :bids="auctionStore.auction.bids" />
				</div>
			</div>
		</div>
		<div class="my-8" v-if="auctionStore.auctions && auctionStore.auctions.length > 1">
			<h2 class="mx-2 text-lg font-bold mb-2 border-b-2 border-slate-200">Similar Auctions</h2>
			<div class="overflow-x-auto flex space-x-4 mt-6 scroll-px-4">
				<div  v-for="auction in auctionStore.auctions"
					class="first:ml-2 last:mr-2 flex-none lg:max-w-[15%] md:max-w-[30%] max-w-[40%] bg-white rounded-md">
						<auction-tile
							v-if="auction.id != auctionStore.auction.id"
							:auction="auction"
							:key="`auction-tile-${auction.id}`"
						/>
				</div>
			</div>
		</div>
		<bid-preview
			v-if="state.showBidModal"
			:current-bid="state.bid"
			:auction="auctionStore.auction"
			@close="state.showBidModal = false"
		/>
		<error-modal
			v-if=" !! state.error && state.showErrorModal"
			:component="GeneralErrorComponent"
			:error="state.error"
			@close="state.showErrorModal = false"
		/>

	</div>
</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch } from "vue";
import AuctionLoading from '@/components/AuctionLoading.vue'
import StickyAuctionBanner from '@/components/StickyAuctionBanner.vue'
import AuctionImageCarousel from "@/components/AuctionImageCarousel.vue";
import AuctionCountdown from "@/components/AuctionCountdown.vue";
import AuctionBidHistory from "@/components/AuctionBidHistory.vue";
import AuctionBreadcrumbs from "@/components/AuctionBreadcrumbs.vue";
import ErrorModal from "@/components/Errors/ErrorModal.vue";
import GeneralErrorComponent from "@/components/Errors/GeneralError.vue";
import BidPreview from "@/components/BidPreview.vue"
import { useRoute, useRouter } from 'vue-router';
import { useAuctionStore } from '@/stores/AuctionStore'
import { useAppStore } from '@/stores/AppStore'
import Currency from '@/types/Currency'
import AuctionTile from "@/components/AuctionTile.vue";
import MainNavigation from "../components/MainNavigation.vue";
import BN from 'bn.js'
import { useWalletStore } from "@/stores/WalletStore";
import { debounce } from "@/util/debounce";
import currencyFormat from "@/util/currencyFormat";
import ErrorModal from "@/components/Errors/ErrorModal.vue";

export default defineComponent({
	components: { MainNavigation, AuctionBreadcrumbs, AuctionTile, AuctionLoading, StickyAuctionBanner, AuctionImageCarousel, AuctionCountdown, AuctionBidHistory, BidPreview, ErrorModal },
	setup(props) {
		const route = useRoute();
		const router = useRouter();
		const auctionStore = useAuctionStore();
		const appStore = useAppStore();
		const walletStore = useWalletStore()

		const scrollHeader = ref(null);
		// const scrollBidBtn = ref(null);
		const auctionTitle = ref(null);
		const customBid = ref(null);

		const observers = [];

		const state = reactive({
			loaded: false,
			showStickyBanner: false,
			bid: null,
			showBidModal: false,
			error: null,
			showErrorModal: false,
		});

		onMounted(() => {
			loadAuction(route.params.auction)
		})

		async function loadAuction(id: number) : Promise {
			state.loaded = false
			return auctionStore
				.fetchAuction(id)
				.then(() => {
					updateMinimumBid()
					state.loaded = true;
					setTimeout(attachObservers, 1)
				});
		}

		watch(() => {
			if (auctionStore.auction) {
				return auctionStore.auction.high_bid
			}

			return auctionStore.auction
		}, () => {
			updateMinimumBid()
		})

		function validateBid() : boolean {
			state.error = null;
			const currentBalance = new BN(
				walletStore.currentBalance.toString()
			)

			const minBid = new BN(minimumBid.value.toString())

			const currentBid = new BN( Currency.fromDecimal(state.bid).toString() )

			if (currentBalance.lt(currentBid)) {
				// console.error('insufficient funds')
				state.error = `You do not have enough ${appStore.tokenSymbol}`
				return false
			}

			if (currentBid.lt(minBid)) {
				// console.error('too low')
				state.error = `Your bid must be more than ${currencyFormat(minimumBid.value.toDecimal())} ${appStore.tokenSymbol}`
				return false
			}

			return true
		}

		const keyupValidator = debounce(validateBid, 50)

		const minimumBid = computed(() => {
			return currentBid.value.add(auctionStore.auction.bid_increment)
		})

		function updateMinimumBid() {
			state.bid = minimumBid.value.toDecimal()
		}

		async function handleBidClicked() {
			state.showErrorModal = false;

			const bidValid = validateBid()
			if (false == bidValid) {
				// show generic error
				// console.error('invalid bid', state.error)
				state.showErrorModal = true;
				return
			}

			state.showBidModal = true
		}

		function attachObservers() {
			clearObservers();
			observers.push(observe(auctionTitle.value, (scrollTop, ev) => {
				state.showStickyBanner = scrollTop <= 5
				// scrollHeader.value.classList.toggle("hidden",
				// 	! (scrollTop <= 5)
				// );
			}));

			// observers.push(observe(customBid.value, (scrollTop, ev) => {
			// 	if ( ! scrollBidBtn) return;

			// 	scrollBidBtn.value.classList.toggle("hidden",
			// 		! (scrollTop < (scrollHeader.value.offsetHeight - (customBid.value.offsetHeight * .05)))
			// 	);
			// }));
		}

		function clearObservers() {
			observers.splice(0, observers.length);
		}

		onUnmounted(() => {
			clearObservers()
		});

		function observe(el, callback) {
			const listener = (ev) => {
				callback(el.offsetTop - document.documentElement.scrollTop, ev);
			}

			document.addEventListener('scroll', listener);
			return listener;
		}

		const currentBid = computed(() => {
			if (auctionStore.auction.high_bid) {
				return new Currency(auctionStore.auction.high_bid)
			}

			return new Currency(auctionStore.auction.starting_bid)
		})

		return {
			state,
			scrollHeader,
			// scrollBidBtn,
			walletStore,
			auctionTitle,
			customBid,
			auctionStore,
			appStore,
			Currency,
			currentBid,
			keyupValidator,
			handleBidClicked,
			GeneralErrorComponent,
			minimumBid
		}
	}
})
</script>
