<template>
  <div class="flex flex-col min-h-screen">
    <div v-if="walletStore.currentChain != CHAIN && metamask.state.connected"
      class="text-sm md:text-lg bg-orange-400 text-slate-900 text-center py-2 px-6 border-b-2 border-orange-500">
      The BPX Auction is only available on the Ethereum mainnet.
      <button class="text-blue-700 underline"
        @click.stop="selectNetwork(CHAIN)"
      >Change networks</button> to continue.
    </div>

    <main-navigation />
    <router-view :key="route.fullPath.replace(/(#.*)$/, '')"></router-view>
    <div class="flex-auto"></div>
    <div class="mt-12 bg-gray-900 text-center py-12 text-slate-300">
      <p>Blokpax Collective</p>
      <img src="/image/bpx-collective.svg" class="aspect-square w-24 mx-auto">
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRoute } from "vue-router";
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useWalletStore, BPX_ADDRESS } from '@/stores/WalletStore'
import metamask from '@/util/metamask'

const route = useRoute()
const auctionStore = useAuctionStore()
auctionStore.fetchAuctions()

const CHAIN = import.meta.env.VITE_CHAIN
const walletStore = useWalletStore()
walletStore.monitorCurrency(BPX_ADDRESS, CHAIN)
walletStore.init(false)

window.Pusher = Pusher
const echo = new Echo({
	broadcaster: 'pusher',
	key: import.meta.env.VITE_PUSHER_APP_KEY,
	cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
	forceTLS: true,
});

const selectNetwork = async(id: number) : Promise => {
  // console.log(walletStore.currentChain)
  await metamask.requestChain(id)
}

echo.channel('bid-received').listen('BidReceived', (bid) => {
  // console.log('new bid received', bid)
  auctionStore.addBid(bid)
});

echo.channel('bid-received').listen('NewHighBid', ({auction}) => {
  // console.log('new high bid received', auction)
  auctionStore.recordHighBid(auction)
});
</script>
