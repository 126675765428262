<template>
	<div class="flex-grow">
		<div class="bg-[url('/image/hero-bg.jpeg')] bg-cover h-1/2 text-center flex flex-col justify-center items-center
			py-12 sm:py-24 md:py-44 lg:py-60">
			<h1 class="text-3xl font-semibold">Welcome to BPX Auctions</h1>
			<h2 class="text-lg mt-2">Unlock the Door to an Exclusive World of Collectibles!</h2>
			<p class="mt-6">
				<a href="#auctions" class="btn-primary-lg">Join our Auctions Now!</a>
			</p>
		</div>
		<div v-if="auctionGroups && auctionGroups.length"
			id="auctions" class="sm:flex flex-shrink-0 flex-grow-0 flex-wrap space-y-6 sm:space-y-0 sm:space-x-6 px-6 my-6 justify-center
			md:mt-12 md:space-x-12 lg:mt-16 lg:space-x-16">
			<div v-for="group in auctionGroups" :key="`ag-${group.id}`"
				class="px-6 py-10 sm:py-6 drop-shadow-md rounded-xl sm:w-1/2 md:w-[33%] lg:w-[20%] overflow-hidden
				bg-cover aspect-square relative"
				:style="`background-image: url('${group.image}');`">
				<!-- <div class="absolute inset-0 grid grid-cols-8 sm:grid-cols-5 opacity-20 z-0">
				</div> -->
				<router-link :to="{ name: 'auction-listings', params: { 'group': group.slug }}"
					class="absolute inset-0">
					<div class="text-center absolute bottom-0 right-0 left-0 text-white pb-6 ml-14">
						<p class="hidden text-xl font-semibold">{{ group.name }}</p>
						<countdown :to="group.end_at">
							<template #default="slotProps">
								<div class="mt-4">
									<p class="text-3xl">{{ formatCountdown(slotProps.duration) }}</p>
									<p class="text-gray-200 text-xs mt-1">Time Remaining</p>
								</div>
							</template>
						</countdown>
					</div>
				</router-link>
			</div>
		</div>
		<div v-else>
			<p class="text-center text-xl text-slate-400 py-6">There are no active auctions right now.</p>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import Countdown from '@/components/Countdown.vue'
import { useTaxonomyStore } from "@/stores/TaxonomyStore";

export default defineComponent({
	components: { Countdown },
	setup(props) {
		const auctionStore = useAuctionStore()
		const taxonomyStore = useTaxonomyStore()

		const auctionGroups = computed(() => {
			return auctionStore.auctionGroups
		})

		const formatCountdown = function(duration: Duration) : string {
			if ( ! duration) return ''

			let units;
			if (duration.days > 0) {
				units = ['Days', 'Hours'];
			} else if (duration.hours > 0) {
				units = ['Hours', 'Minutes']
			} else if (duration.minutes > 0) {
				units = ['Minutes', 'Seconds']
			} else {
				units = ['Seconds']
			}

			let output = []
			for (const unit of units) {
				const v = duration[ unit.toLowerCase() ]
				if (v > 0) {
					const plural = v == 1;
					let label = unit;
					if (plural) {
						label = label.substring(0, unit.length - 1)
					}

					output.push(
						`${v} ${label}`
					)
				}
			}

			return output.join(', ')
		}

		return {
			auctionStore,
			auctionGroups,
			formatCountdown,
		}
	}
})
</script>
