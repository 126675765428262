<template>
	<div class="text-sm text-center">
		<countdown :to="targetTimestamp" v-if="targetTimestamp"
			@finished="updateTargetTimestamp">
			<template #default="slotProps">
				<p class="leading-5 font-medium">
					<span class="text-2xl font-semibold">{{  formatCountdown(slotProps.duration) }}</span>
					<br>
					<span class="font-normal">{{  countdownLabel }}</span>
					<!-- slotProps.formattedDuration }} -->
					<!-- 5 days, 12 hours, 32 minutes -->
				</p>
			</template>
			<template #finished>
				{{ timerFinishedOutput }}
			</template>
		</countdown>
		<p class="text-slate-500 mt-2 text-xs">
			{{ countdownDescription }}
			<br>
			{{ fullEndDate }}
		</p>
	</div>
</template>
<script lang="ts">
import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { Duration, DateTime } from 'ts-luxon'
import Countdown from "@/components/Countdown.vue";

export default {
	components: { Countdown },
	props: {
		auction: {
			required: true
		}
	},

	setup(props) {
		const countdownLabel = computed(() => {
			const now = DateTime.now();
			if (now < props.auction.auction_group.start_at) {
				return 'Auction Begins'
			}

			return 'Time Remaining'
		})

		function countToTimestamp() {
			const now = DateTime.now();

			if (now < props.auction.auction_group.start_at) {
				return props.auction.auction_group.start_at;
			}

			if (now < props.auction.auction_group.end_at) {
				return props.auction.auction_group.end_at
			}

			// if (props.auction.last_bid_at < props.auction.auction_group.end_at) {
			// 	return props.auction.auction_group.end_at
			// }

			return props.auction.last_bid_at.plus({
				seconds: props.auction.extended_bidding_seconds
			})
		}

		let targetTimestamp = ref(null);
		function updateTargetTimestamp() {
			// console.log('updating timestamp')
			targetTimestamp.value = countToTimestamp()
		}

		onMounted(() => {
			updateTargetTimestamp()
		})

		const countdownDescription = computed(() : string => {
			const now = DateTime.now()

			if (now < props.auction.auction_group.start_at) {
				return 'Starts on'
			}

			if (now < props.auction.auction_group.end_at) {
				if (props.auction.extended_bidding_seconds.as('seconds') > 0) {
					return 'Extended bidding starts on'
				}

				return 'Ends on'
			}

			if (props.auction.last_bid_at < props.auction.auction_group.end_at) {
				return 'Extended bidding ends on'
			}

			return 'Extended bidding ends on'
		})

		const fullEndDate = computed(() => {
			if ( ! targetTimestamp.value) return ''
			return targetTimestamp.value.toFormat('EEE, ff ZZZZ')
		})

		const formatCountdown = function(duration: Duration) : string {
			if ( ! duration) return ''

			let units;
			if (duration.days > 0) {
				units = ['Days', 'Hours'];
			} else if (duration.hours > 0) {
				units = ['Hours', 'Minutes']
			} else if (duration.minutes > 0) {
				units = ['Minutes', 'Seconds']
			} else {
				units = ['Seconds']
			}

			let output = []
			for (const unit of units) {
				const v = duration[ unit.toLowerCase() ]
				if (v > 0) {
					const plural = v == 1;
					let label = unit;
					if (plural) {
						label = label.substring(0, unit.length - 1)
					}

					output.push(
						`${v} ${label}`
					)
				}
			}

			return output.join(', ')
		}

		const timerFinishedOutput = computed(() => {
			const now = DateTime.now()

			if (now < props.auction.auction_group.end_at) {
				return 'Auction starting'
			}

			if (now < props.auction.auction_group.end_at.plus({ seconds: props.auction.extended_bidding_seconds })) {
				return 'Starting extended bidding'
			}

			return 'Auction ending'
		})

		return {
			props,
			countdownLabel,
			countdownDescription,
			countToTimestamp,
			targetTimestamp,
			formatCountdown,
			fullEndDate,
			updateTargetTimestamp,
			timerFinishedOutput
		}
	}
}
</script>
