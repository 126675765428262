<template>
	<div class="space-y-4 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 mx-4 mt-4">
		<!-- user profile tile -->
		<div class="border-2 border-slate-300 rounded-xl p-4 grid grid-cols-[1fr,2fr] gap-4 items-center">
			<div class="flex-grow-0 text-center"><jazzicon :address="metamask.state.wallet" :seed="7" /></div>
			<div class="flex-1 truncate">
				<p><strong>Your wallet</strong></p>
				<p class="truncate">{{ metamask.state.wallet }}</p>
				<p class="text-xs text-gray-400">
					<i class="fa-solid fa-wallet"></i>
					{{ $format(walletStore.currentBalance.toDecimal()) }}
					{{ appStore.tokenSymbol }}
				</p>


				<div class="grid grid-cols-2 gap-4 text-center text-sm mt-4">
					<div class="bg-white rounded-xl p-4">
						<p class="text-base font-medium">{{ bids.length }}</p>
						<p class="text-slate-500 ">Bids</p>
					</div>
					<div class="bg-white rounded-xl p-4">
						<p class="text-base font-medium">{{ watchedAuctions.length }}</p>
						<p class="text-slate-500 ">Watched</p>
					</div>
				</div>
			</div>
		</div>


		<div>
			<div class="border-b-2 border-slate-300 flex justify-between items-center px-2">
				<h4 class="font-semibold">Winning Auctions</h4>
			</div>

			<div v-if=" ! winningAuctions || ! winningAuctions.length">
				<p class="text-gray-500">You are not currently the high bidder on any auctions</p>
			</div>
			<div v-else class="mt-2">
				<router-link v-for="auction in winningAuctions" :key="`winning-auction-${auction.id}`"
					:to="{ name: 'auction', params: { auction: auction.id, group: auction.auction_group.slug }}"
					class="p-2 block hover:bg-slate-100 rounded-lg">
					<auction-listing-condensed
						:auction="auction"
					/>
				</router-link>
			</div>
		</div>

		<!-- watched auction tile -->
		<div>
			<div class="border-b-2 border-slate-300 flex justify-between items-center px-2">
				<h4 class="font-semibold">Watched Auctions</h4>
				<router-link to="/account/watched" class="text-sm space-x-1 group">
					<span class="href-primary hidden group-hover:inline">View All</span>
					<i class="fa-solid fa-eye text-slate-300"></i>
				</router-link>
			</div>

			<div v-if="! watchedAuctions.length">
				<p class="text-gray-500">You're not watching any auctions</p>
			</div>
			<div v-else class="mt-2">
				<router-link v-for="auction in watchedAuctions.slice(0, 3)"
					:to="{ name: 'auction', params: { auction: auction.id, group: auction.auction_group.slug}}"
					class="p-2 block hover:bg-slate-100 rounded-lg">
					<auction-listing-condensed
						:auction="auction"
					/>
				</router-link>
			</div>
		</div>

		<div>
			<div class="border-b-2 border-slate-300 flex justify-between items-center px-2">
				<h4 class="font-semibold">Bid History</h4>
				<router-link to="/account/bids" class="text-sm space-x-1 group">
					<span class="href-primary hidden group-hover:inline">View All</span>
					<i class="fa-solid fa-eye text-slate-300"></i>
				</router-link>
			</div>

			<div v-if=" ! bids.length || ! auctionStore.auctions || ! auctionStore.auctions.length">
				<p class="text-gray-500">You have not made any bids</p>
			</div>
			<div v-else class="mt-2">
				<router-link v-for="bid in bids.slice(0, 3)"
					:to="{ name: 'auction', params: { auction: bid.auction_id, group: auctionGroup(bid.auction_id).slug }}"
					class="p-2 block hover:bg-slate-100 rounded-lg">
					<bid-history-item
						:bid="bid"
					/>
				</router-link>
			</div>
		</div>

	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, onMounted, computed } from "vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import BidHistoryItem from "@/components/BidHistoryItem.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useAuthStore, AuthToken } from "@/stores/AuthStore";
import { useProfileStore } from '@/stores/ProfileStore'
import metamask from '@/util/metamask'
import Currency from "@/types/Currency";
import { useAppStore } from "@/stores/AppStore";
import { useWalletStore } from "@/stores/WalletStore";
import Auction, { AuctionGroup } from "@/types/Auction";

export default defineComponent({
    components: { AuctionListingCondensed, BidHistoryItem },
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore();
		const authStore = useAuthStore();
		const profileStore = useProfileStore();
		const walletStore = useWalletStore()

		onMounted(() => {
			auctionStore.fetchAuctions();
		})

		const watchedAuctions = computed(() => {
			if ( ! profileStore.watched || 0 == profileStore.watched.length) {
				return []
			}

			return profileStore.watched;
		})

		const bids = computed(() => {
			if ( ! profileStore.bids || 0 == profileStore.bids.length) {
				return []
			}

			return profileStore.bids
		})

		const winningAuctions = computed(() => {
			if ( ! walletStore.wallet) {
				return []
			}

			if ( ! auctionStore.auctions || auctionStore.auctions.length == 0) {
				return []
			}

			return auctionStore.auctions.filter(
				(a: Auction) => {
					return a.high_bidder && a.high_bidder?.toLowerCase() == walletStore.wallet.toLowerCase()
				}
			)
		})

		function auctionGroup(auctionId: number) : AuctionGroup | null {
			return auctionStore.auctions?.filter(a => a.id == auctionId)[0].auction_group || null
		}

		// function auctionGroup(auctionId: number) {
		// 	return auctionStore.fetchAuctions().then((auctions) => {
		// 		return auctions.filter(
		// 			a => a.id == auctionId
		// 		)[0].auction_group || null
		// 	})
		// }

		return {
			Currency,
			appStore,
			auctionStore,
			authStore,
			walletStore,
			metamask,
			watchedAuctions,
			bids,
			winningAuctions,
			auctionGroup,
		}
	}
})
</script>
