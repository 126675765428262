<template>
	<auction-list
		title="Watched Auctions"
		:auctions="auctions">
		<template #auction-item="{ auction }">
			<router-link :to="{ name: 'auction', params: { auction: auction.id, group: auction.auction_group.slug }}"
				class="p-2 block hover:bg-slate-100 rounded-lg">
				<auction-listing-condensed
					:auction="auction"
				/>
			</router-link>
		</template>
	</auction-list>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, onMounted } from "vue";
import AuctionList from "@/pages/Account/AuctionList.vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";

export default defineComponent({
	components: { AuctionList, AuctionListingCondensed },
	setup(props) {
		const auctionStore = useAuctionStore()
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()

		onMounted(async() => {
			const t = await authStore.authToken()
			return profileStore.loadAccount(
				t
			);
		})

		const auctions = computed(() => {
			if ( ! profileStore.watched || 0 == profileStore.watched.length) {
				return []
			}

			return profileStore.watched
		});

		return {
			auctions,
			appStore
		}
	}
})
</script>
