import { defineStore } from 'pinia'
import metamask from '@/util/metamask'

const SESSION_TIMEOUT = 120 * 60;

export class AuthToken {
	public data : AuthTokenData;

	constructor(token: AuthTokenData) {
		this.data = token
	}

	wallet(): string {
		return this.data.wallet;
	}

	persist(): void {
		window.localStorage.setItem(this.data.wallet, this.toString())
	}

	valid() : boolean {
		const now = Math.ceil(Date.now() / 1000);

		if (now - this.data.timestamp > SESSION_TIMEOUT) {
			return false
		}

		if ( ! metamask.verifySignature(this.data.message, this.data.signature, this.data.wallet)) {
			return false
		}

		const regexp = new RegExp(`^#nonce:${this.data.nonce}.${this.data.timestamp}#`, 'm')

		if ( ! this.data.message.match(regexp)) {
			return false;
		}

		return true;
	}

	toString() : string {
		return JSON.stringify(this.data)
	}

	base64_encode() : string {
		return window.btoa(this.toString())
	}

	static clear(wallet: string) : void {
		window.localStorage.removeItem(wallet)
	}

	static fromString(token: string) : AuthToken {
		return new AuthToken(JSON.parse(token))
	}

	static fetchForWallet(wallet : string | null) : AuthToken | null {
		if ( ! wallet) {
			return null
		}

		const t = window.localStorage.getItem(wallet)

		if (t) {
			const authToken = AuthToken.fromString(t);

			if (authToken.valid()) {
				return authToken
			}
		}

		return null;
	}

	static async requestSignature(wallet: string) : Promise<AuthToken | null> {
		const nonce = generateNonce()
		const timestamp = Math.ceil(Date.now() / 1000)
		const message = `Welcome to BPX.auction!

To manage your account, you must first authenticate your wallet.

Sign this message in order to verify your identity.

#nonce:${nonce}.${timestamp}#`

		try {
			const signature = await metamask.sign(message)
				// .catch((err) => {
				// 	console.log('error here')
				// 	throw err;
				// })

			if (signature) {
				return new AuthToken({
					signature,
					nonce,
					timestamp,
					message,
					wallet,
					authenticated: true,
				} as AuthTokenData);
			}
		} catch (e) {
			console.error('error', e);
			// throw e;
		}

		return null;
	}
}

interface AuthTokenData {
	nonce: string
	timestamp: number
	message: string
	wallet: string
	signature: string
	authenticated: boolean
}

function generateNonce() {
	const alphabet =
		'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
	let nonce = ''
	for (let i = 0; i < 10; i++) {
		const rand = Math.floor(Math.random() * (alphabet.length - 1))
		nonce += alphabet.substr(rand, 1)
	}

	return nonce
}

export const useAuthStore = defineStore('auth', {
	state: () => ({
	}),

	actions: {
		async authenticate() : AuthToken | null {
			const token = await AuthToken.requestSignature(metamask.state.wallet)

			if (token) {
				token.persist()
				return token
			}

			AuthToken.clear(metamask.state.wallet)
			return null
		},

		authToken(wallet: string | undefined) : AuthToken | null {
			return AuthToken.fetchForWallet(wallet || metamask.state.wallet)
		},
	},

	getters: {
		authenticated() : boolean {
			const token = this.authToken()
			return token && token.valid()
		}
	}
})
