<template>
	<div class="flex items-center space-x-2" v-if="auction">
		<div class="border-2 border-slate-300 rounded-lg overflow-hidden">
			<img :src="auction.images[0].uri" class="w-8">
		</div>
		<div class="flex-grow">
			<div class="flex">
				<div class="flex-grow">
					<span class="font-medium">{{ auction.title }}</span>
				</div>
				<div class="text-right">
					<p class="text-gray-600">
						{{ $format(bid.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>
				</div>
			</div>
			<div class="text-sm text-slate-500/90 leading-none space-x-1">
				<i class="fa-fw fa-regular fa-clock text-slate-400"></i>
				<timeago :datetime="props.bid.bid_at.toISO()" />
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { useAppStore } from "@/stores/AppStore";
import { defineComponent, PropType, computed } from "vue";
import Currency from "@/types/Currency";
import { countdown } from '@/util/countdownAuction'
import { useAuctionStore } from "@/stores/AuctionStore";

export default defineComponent({
	props: {
		bid: {
			required: true,
		}
	},
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore()

		const auction = computed(() => {
			if ( ! auctionStore.auctions || 0 == auctionStore.auctions.length) {
				return null
			}

			return auctionStore.auctions.filter(
				a => a.id == props.bid.auction_id
			)[0] || null
		})

		const bid = computed(() => {
			return new Currency(props.bid.bid)
		})

		return {
			props,
			Currency,
			appStore,
			auction,
			bid
		}
	}
})
</script>
