<template>
	<div class="text-center flex items-stretch bg-slate-200">
		<router-link class="hidden md:block flex-auto -mb-[1px] p-4 pt-5 text-slate-500 border-b-2 border-slate-300 hover:border-b-2 hover:border-sky-600 hover:text-sky-600"
			:to="{ name: 'account' }"
			active-class="text-slate-900 font-medium">
			My Account
		</router-link>
		<router-link class="flex-auto -mb-[1px] p-4 pt-5 text-slate-500 border-b-2 border-slate-300 hover:border-b-2 hover:border-sky-600 hover:text-sky-600"
			to="/account/watched"
			active-class="text-slate-900 font-medium">
			Watched
		</router-link>
		<router-link class="flex-auto -mb-[1px] p-4 pt-5 text-slate-500 border-b-2 border-slate-300 hover:border-b-2 hover:border-sky-600 hover:text-sky-600"
			to="/account/bids"
			active-class="text-slate-900 font-medium">
			Bids
		</router-link>
		<router-link class="flex-auto -mb-[1px] p-4 pt-5 text-slate-500 border-b-2 border-slate-300 hover:border-b-2 hover:border-sky-600 hover:text-sky-600"
			to="/account/won"
			active-class="text-slate-900 font-medium">
			Won
		</router-link>
		<router-link class="flex items-center justify-center flex-auto -mb-[1px] p-4 pt-5 text-slate-500 border-b-2 border-slate-300 hover:border-b-2 hover:border-sky-600 hover:text-sky-600"
			to="/account/sold"
			active-class="text-slate-900 font-medium">
			Sold
			<span v-if="soldAuctions > 0"
				class="ml-1 px-2 text-xs text-slate-800 bg-slate-400 rounded-full font-mono">
				{{ soldAuctions }}
			</span>
		</router-link>
	</div>
</template>
<script lang="ts">
import { useProfileStore } from "@/stores/ProfileStore";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	setup(props) {
		const profileStore = useProfileStore()
		const soldAuctions = computed(() => {
			if ( ! profileStore.owned.length) {
				return 0
			}

			return profileStore.owned.length;
		})

		return {
			props,
			soldAuctions,
		}
	}
})
</script>
