<template>
	<div>
		<carousel :items-to-show="1">
			<slide v-for="(image, key) in props.images"
				:key="`slide-${key}`">
					<img class="rounded-md border-2 border-gray-300"
						:src="image.uri"
					/>
			</slide>

			<template #addons>
				<navigation />
				<pagination />
			</template>
		</carousel>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, reactive } from "vue";
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import { AuctionImage } from "@/types/Auction";

export default defineComponent({
	components: { Carousel, Slide, Navigation, Pagination },
	props: {
		images: {
			type: Array,
			required: true,
		}
	},

	setup(props) {
		const state = reactive({
			selectedImage: 0 as Number,
		});

		const currentImage = computed(() => {
			return props.images[ state.selectedImage ]
		})

		return {
			props,
			state,
			currentImage
		}
	}
})
</script>
