<template>
	<slot
	 	v-if=" ! state.isFinished"
		:duration="state.duration"
		:leadingZero="leadingZero"
		:formattedDuration="formattedDuration">
		{{ formattedDuration }}
	</slot>
	<slot name="finished"
		v-if="state.isFinished">
		Now
	</slot>
</template>
<script lang="ts">
import { PropType, computed, onMounted, onUnmounted, reactive } from 'vue'
import { Duration, isAfter, intervalToDuration } from 'date-fns';
import { DateTime } from 'ts-luxon';

interface IState {
	duration: Duration | null;
	isFinished: boolean;
}

export default {
	props: {
		to: {
			type: Object as PropType<DateTime>,
			required: true
		}
	},
	emits: ['finished'],
	setup(props, { emit: $emit }) {
		let interval: number;

		const state: IState = reactive({
			duration: null,
			isFinished: false,
		});

		onMounted(() => {
			updateCountdown();
			interval = window.setInterval(updateCountdown, 300);
		});

		onUnmounted(() => {
			window.clearInterval(interval);
		});

		function leadingZero(i:number | undefined) {
			if ( i == undefined ) {
				return '00';
			}

			if (i < 10) {
				return `0${i}`;
			}

			return i;
		}

		function updateCountdown() {
			try {
				state.isFinished = isAfter(DateTime.now().toJSDate(), props.to.toJSDate());
				if (state.isFinished) {
					window.clearInterval(interval);
					$emit('finished');
					return;
				}

				state.duration = intervalToDuration({
					start: new Date(),
					end: props.to.toJSDate(),
				});
			} catch (e) {
				console.error(e);
			}
		}

		const formattedDuration = computed(() => {
			if ( ! state.duration ) return '';

			let string = [
				leadingZero(state.duration.hours),
				leadingZero(state.duration.minutes),
				leadingZero(state.duration.seconds),
			].join(':');

			if (state.duration.days) {
				string = `${state.duration.days} day${state.duration.days != 1? 's' : ''}, ${string}`;
			}

			return string;
		});

		return {
			state,
			leadingZero,
			formattedDuration,
		};
	},
}
</script>
