<template>
	<div class="">
		<auction-breadcrumbs
			:group="group"
		/>

		<div v-if=" ! state.loaded">
			<p class="text-center p-6 sm:p-8">Loading Auctions...</p>
		</div>
		<div v-else class="h-full flex-auto p-6 sm:p-8">

			<div class="flex items-center space-x-4">
				<button class="md:hidden inline-block leading-0 drop-shadow-md rounded-xl px-3 aspect-square bg-white hover:drop-shadow-lg
					hover:text-sky-600 hover:border-sky-600"
					@click="state.showTokenFilter = true">
					<i class="inline-block fa-fw fa-solid fa-bars-filter"></i>
				</button>

				<p class="text-xl font-medium">BPX Auctions</p>
			</div>

			<div class="h-full sm:flex justify-start">
				<token-filter
					:tags="auctionTags"
					class="md:min-w-[275px] lg:min-w-[350px] md:block"
					:class=" ! state.showTokenFilter? ['hidden'] : []"
					@close="state.showTokenFilter = false"
				/>

				<div class="mt-6 md:mt-0 md:ml-6 flex-auto max-w-screen-md space-y-6 overflow-y-auto" >
					<auction-listings v-for="x in 2"
						:auctions="filteredAuctions"
						/>
				</div>
				<!-- <div class="sm:flex-grow sm:min-w-sm flex-shrink-0 w-1/4"></div> -->
			</div>
			<!-- <div class="grid grid-cols-2 sm:grid-cols-4 gap-5 mt-4">
				<div v-for="auction in auctionStore.filteredAuctions"
					class="bg-white rounded-lg">
					<auction-tile
						:auction="auction"
						:key="`auction-idx-${auction.id}`"
					/>
				</div>
			</div> -->
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref, reactive, computed} from "vue";
import { RouterLink, useRoute } from 'vue-router';
import AuctionTile from "@/components/AuctionTile.vue";
import MainNavigation from "../components/MainNavigation.vue";
import TokenFilter from "@/components/TokenFilter.vue";
import AuctionBreadcrumbs from "@/components/AuctionBreadcrumbs.vue";
import { useAuctionStore } from '@/stores/AuctionStore';
import { useTaxonomyStore } from '@/stores/TaxonomyStore';
import { Auction } from '@/types/Auction'
import AuctionListings from "@/components/AuctionListings.vue";

interface IndexState {
	loaded: boolean
	showTokenFilter: true
}

export default defineComponent({
	components: { MainNavigation,  RouterLink, AuctionTile, TokenFilter, AuctionListings, AuctionBreadcrumbs },
	setup(props) {
		const auctionStore = useAuctionStore();
		const taxonomyStore = useTaxonomyStore()
		const route = useRoute()
		const state : IndexState = reactive({
			loaded: false,
			showTokenFilter: false,
		})

		onMounted(() => {
			const promises = [
				auctionStore.fetchAuctions(),
				taxonomyStore.fetchTaxonomy(),
			]

			Promise.all(promises).then(() => {
				state.loaded = true
			})
		});

		const filterContainer = ref(null);
		function toggleFilters() {
			if (filterContainer.value)
				filterContainer.value.classList.toggle('hidden');
		}

		const group = computed(() => {
			return auctionStore.auctionGroups.filter(g => g.slug == route.params.group)[0]
		})

		const auctions = computed(() => {
			if ( ! state.loaded || ! auctionStore.auctions.length) {
				return [] as Auction[];
			}

			return auctionStore.groupAuctions(group.value.id)
		})

		const auctionTags = computed(() => {
			let tagIds = new Set()
			auctions.value.map(a => {
				a.tags.map(t => tagIds.add(t.id))
			})

			return tagIds
		})

		const filteredAuctions = computed(() => {
			return auctionStore.filteredAuctions.filter(a => a.auction_group.id == group.value.id)
		})

		return {
			state,
			filterContainer,
			toggleFilters,
			auctionStore,
			filteredAuctions,
			group, auctions,
			auctionTags,
		}
	}
})
</script>
