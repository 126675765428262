<template>
	<div v-if="state.authToken">
		<account-nav />
		<component :is="accountComponent" />
	</div>
	<div v-else class="text-center my-12">
		<wallet-connected>
			<template #default>
				<div class="p-4 space-y-4">
					<p class="font-medium text-lg">
						Connect your wallet to continue.
					</p>
					<button class="btn-primary-lg"
						@click.stop="metamask.connect">
						Connect your Wallet
					</button>
				</div>
			</template>
			<template #connected>
				<div class="px-4 space-y-4">
					<p class="font-medium text-lg">
						To access your account, please click "Authenticate" below.
					</p>
					<p class="text-slate-500 text-sm">
						You will be asked to sign a message to verify that you
						are the owner of this wallet.
					</p>
					<p class="text-slate-500 text-sm">
						This message is not executable on the blockchain, and does
						not allow access to any of your assets.
					</p>

					<p>
						<button class="btn-primary-lg"
							@click.stop="authenticate">
							Authenticate
						</button>
					</p>
				</div>
			</template>
		</wallet-connected>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive } from "vue";

// components
import MainNavigation from "@/components/MainNavigation.vue";
import AccountNav from "@/components/AccountNav.vue";
import AuctionTile from "@/components/AuctionTile.vue";
import AuctionListingItem from "@/components/AuctionListingItem.vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import BidHistoryItem from "@/components/BidHistoryItem.vue";
import WalletConnected from "@/components/WalletConnected.vue";
import Home from '@/pages/Account/Home.vue'
import SoldAuctions from '@/pages/Account/SoldAuctions.vue'
import WatchedAuctions from '@/pages/Account/WatchedAuctions.vue'
import WonAuctions from '@/pages/Account/WonAuctions.vue'
import BidHistory from '@/pages/Account/BidHistory.vue'

import { useAuctionStore } from "@/stores/AuctionStore";
import { useAuthStore, AuthToken } from "@/stores/AuthStore";
import { useProfileStore } from '@/stores/ProfileStore'
import metamask from '@/util/metamask'
import Currency from "@/types/Currency";
import { useAppStore } from "@/stores/AppStore";
import { Auction } from "@/types/Auction";
import { useRoute } from "vue-router";

export default defineComponent({
    components: { MainNavigation, AccountNav, AuctionTile, AuctionListingItem, AuctionListingCondensed, BidHistoryItem, WalletConnected },
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore();
		const authStore = useAuthStore();
		const profileStore = useProfileStore();
		const route = useRoute()

		auctionStore.fetchAuctions();

		const state = reactive({
			authToken: null as  AuthToken | null
		})

		function checkAuthentication() {
			state.authToken = authStore.authToken()

			if (state.authToken) {
				profileStore.loadAccount(state.authToken)
			}
		}

		async function authenticate() {
			try {
				state.authToken = await authStore.authenticate()
				checkAuthentication();
			} catch (e) {
				// ignore
			}
		}

		onMounted(async() => {
			metamask.init(window.ethereum)

			metamask.on('accountChanged', checkAuthentication)
			metamask.on('connected', checkAuthentication)
			checkAuthentication()
		})

		onUnmounted(() => {
			metamask.off('accountsChanged', checkAuthentication)
			metamask.off('connected', checkAuthentication)
		})

		const accountComponent = computed(() => {
			switch (route.params.subpage) {
				case 'sold':
					return SoldAuctions;

				case 'watched':
					return WatchedAuctions;

				case 'won':
					return WonAuctions;

				case 'bids':
					return BidHistory;

				default:
					return Home;
			}
		});

		return {
			Currency,
			appStore,
			auctionStore,
			authStore,
			metamask,
			state,
			accountComponent,
			authenticate,
		}
	}
})
</script>
