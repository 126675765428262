<template>
	<div class="flex items-center space-x-2">
		<div class="border-2 border-slate-300 rounded-lg overflow-hidden">
			<img :src="props.auction.images[0].uri" class="w-8">
		</div>
		<div class="flex-grow">
			<div class="flex">
				<div class="flex-grow">
					<span class="font-medium">{{ props.auction.title }}</span>
				</div>
				<div class="text-right">
					<p class="text-gray-600">
						{{ $format(highBid.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>
				</div>
			</div>
			<div class="text-sm text-slate-500/90 leading-none">
				<i class="fa-fw fa-regular fa-clock text-slate-400"></i>
				{{ timeRemaining }}
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { useAppStore } from "@/stores/AppStore";
import { defineComponent, PropType, computed } from "vue";
import Currency from "@/types/Currency";
import { countdown } from '@/util/countdownAuction'
import { Auction } from '@/types/Auction'

export default defineComponent({
	props: {
		auction: {
			type: Object as PropType<Auction>,
			required: true,
		}
	},
	setup(props) {
		const appStore = useAppStore()
		const {
			duration,
			finished,
			countState,
			Target,
		} = countdown(props.auction)

		const highBid = computed(() => {
			if (props.auction.high_bid) {
				return new Currency(props.auction.high_bid);
			}

			return new Currency(props.auction.starting_bid);
		})

		const timeRemaining = computed(() => {
			const makeLabel = function() : any {
				for (const unit of ['Days', 'Hours', 'Minutes', 'Seconds']) {
					if (duration[ unit.toLowerCase() ] > 0 || unit == 'Seconds') {
						const val = duration[ unit.toLowerCase() ]
						let label = unit;

						if (val == 1) {
							label = label.substring(0, label.length - 1)
						}

						return { val, label: label.toLowerCase() }
					}
				}
			}

			const { val, label } = makeLabel()

			if (countState.value == Target.CountToStart) {
				return `Begins in ${val} ${label}`
			} else  {// if (countState.value == Target.CountToEnd) {
				return `Ends in ${val} ${label}`
			}
		});

		return {
			props,
			Currency,
			appStore,
			highBid,
			timeRemaining
		}
	}
})
</script>
