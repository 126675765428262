<template>
	<auction-list
		title="Won Auctions"
		:auctions="auctions">
		<template #auction-item="{ auction }">
			<div class="flex justify-between items-center py-2">
				<auction-listing-condensed :auction="auction" class="flex-1" />
				<div class="pl-4 space-x-4">
					<router-link :to="{ name: 'auction', params: { auction: auction.id }}">
						<i class="fa-solid fa-eye text-slate-300 hover:text-slate-600"></i>
					</router-link>
					<button class="btn-primary" :disabled="Date.now() < auction.auction_group.end_at.plus({ seconds: auction.extended_bidding_seconds })">Claim Item</button>
				</div>
			</div>
		</template>
	</auction-list>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, onMounted } from "vue";
import AuctionList from "@/pages/Account/AuctionList.vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";

export default defineComponent({
	components: { AuctionList, AuctionListingCondensed },
	setup(props) {
		const auctionStore = useAuctionStore()
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()

		onMounted(async() => {
			const t = await authStore.authToken()
			return profileStore.loadAccount(
				t
			);
		})

		const auctions = computed(() => {
			if ( ! profileStore.won || 0 == profileStore.won.length) {
				return []
			}

			return profileStore.won
		});

		return {
			auctions,
			appStore
		}
	}
})
</script>
