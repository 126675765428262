import { defineStore } from 'pinia'
import { Auction } from '@/types/Auction'

const versionString =
  import.meta.env.MODE === 'development'
    ? import.meta.env.VITE_APP_VERSION + '-dev'
    : import.meta.env.VITE_APP_VERSION

export const useAppStore = defineStore('app', {
	state: () => ({
		debug: import.meta.env.mode === 'development',
		version: versionString,
		initialized: false,
		tokenSymbol: "$BPX",
	}),

	actions: {
	},

	getters: {},
})
