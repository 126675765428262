import { defineStore } from "pinia";
import api from '@/util/api'
import { AuthToken } from '@/stores/AuthStore'
import { Auction, Bid } from '@/types/Auction'
import { useAuctionStore } from "./AuctionStore";

interface Profile {
	email: string
}

export const useProfileStore = defineStore('profile', {
	state: () => ({
		profile: {} as Profile,
		watched: [] as Auction[],
		bid: [] as Auction[],
		won: [] as Auction[],
		owned: [] as Auction[],
		bids: [] as Bid[],
	}),

	actions: {
		async loadAccount(token: AuthToken) {
			const profile = await api.loadProfile(token)

			this.profile = profile.profile
			this.watched = profile.watched_auctions || []
			this.bid = profile.bid_auctions || []
			this.won = profile.won_auctions || []
			this.owned = profile.owned_auctions || []
			this.bids = profile.bids || []
		},

		async watchAuction(token: AuthToken, auctionId: number, watch: boolean) : void {
			const watched = await api.watchAuction(token, auctionId, watch)
			this.watched = watched;
		}
	},

	getters: {
		watchedAuctions() : number[] {
			return this.watched.map((a) => a.id)
		},

		ownedAuctions() : Auction[] {
			return this.owned;
		}
	}
})
