<template>
	<div class="m-4">
		<div class="border-b-2 border-slate-300 mt-4">
			<h2 class="text-xl">Bid History</h2>
		</div>
		<div v-if=" ! bids.length">
			<p class="text-gray-500">You have not made any bids</p>
		</div>
		<div v-else class="mt-2">
			<router-link v-for="bid in bids"
				:to="{ name: 'auction', params: { auction: bid.auction_id, group: auctionGroup(bid.auction_id).slug }}"
				class="p-2 block hover:bg-slate-100 rounded-lg">
				<bid-history-item
					:bid="bid"
				/>
			</router-link>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, onMounted } from "vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";

import BidHistoryItem from "@/components/BidHistoryItem.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";
import { AuctionGroup } from "@/types/Auction";

export default defineComponent({
	components: { BidHistoryItem },
	setup(props) {
		const auctionStore = useAuctionStore()
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()

		onMounted(async() => {
			const t = await authStore.authToken()
			return profileStore.loadAccount(
				t
			);
		})

		const bids = computed(() => {
			if ( ! profileStore.bids || 0 == profileStore.bids.length) {
				return []
			}

			return profileStore.bids
		})

		function auctionGroup(auctionId: number) : AuctionGroup | null {
			return auctionStore.auctions?.filter(a => a.id == auctionId)[0].auction_group || null
		}

		return {
			bids,
			appStore,
			auctionGroup,
		}
	}
})
</script>
